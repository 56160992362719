
@import '_bootstrap';
@import '_layout';
@import '_components';

/**
 * MONITOR ORDINE
 */
[data-namespace="tray-monitor"] {
   .orderDetail {
        &__number {
            
            .uk-card-header {
                border: 7px solid transparent;
                transition: border 200ms;
            }

            &:not(.current) {
                .uk-card-header {
                    border-color: red;
                }
            }

            &.completing {
                .uk-card-header {
                    border-color: green;
                }
            }

            &__code {
                line-height: 0.8;
                font-size: 2em;
            }
        }


        &__tools {

            // &:not(.uk-active) {

            //     position: fixed;
            //     left: 0;
            //     bottom: 0;
            //     width: 100%;
            // }
        }
   }

    #select-order-modal {
        .uk-modal-body {
            max-height: 500px;
            overflow: auto;
        }
    }
}