// 1. Your custom variables and variable overwrites.
$global-link-color: #DA7D02;
$inverse-navbar-nav-item-color : #FFF;


$global-primary-background : #fecb00;
$global-secondary-background : #333;

$button-line-height : 1.2;
$button-large-line-height : $button-line-height;

// 2. Import default variables and available mixins.
@import "../../node_modules/uikit/src/scss/variables-theme.scss";
@import "../../node_modules/uikit/src/scss/mixins-theme.scss";


// 3. Your custom mixin overwrites.
@mixin hook-card() { color: #000; }
@mixin hook-button() {
    padding-top: 1em;
    padding-bottom: 1em;
    text-transform: uppercase;
}
@mixin hook-button-large() {
    @include hook-button();
}
@mixin hook-button-large() {
    @include hook-button();
}

// 4. Import UIkit.
@import "../../node_modules/uikit/src/scss/uikit-theme.scss";


/**
 * Font awesome
 */
$fa-font-path : '../../fonts'; // relative to build directory!
@import "../../node_modules/fontawesome-4.7/scss/font-awesome.scss";
